import React, { useState, useEffect } from "react";
import "./Home.css";
import icon from "../../assets/logo.png";

function Home() {
  const [indianTime, setIndianTime] = useState("");
  const [germanTime, setGermanTime] = useState("");

  useEffect(() => {
    const indianInterval = setInterval(() => {
      setIndianTime(getCurrentTime(5.5));
    }, 1000);

    const germanInterval = setInterval(() => {
      setGermanTime(getCurrentTime(1));
    }, 1000);

    return () => {
      clearInterval(indianInterval);
      clearInterval(germanInterval);
    };
  }, []);

  const getCurrentTime = (offset) => {
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const newTime = new Date(utc + 3600000 * offset);
    return newTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div className="home-container" id="home">
      <img src={icon} alt="Home" className="home-image" />
      <div className="home-content">
        <p>
          Sivasri Engineering Services was established in India on February
          26th, 2024, operating as a solo proprietorship. Based in Bangalore,
          India, the company thrives in the heart of technological innovation.
        </p>
        <p>
          With over 35 years of experience in prestigious industries such as
          Defence and Automotive Tier 1 Companies, the proprietor brings a
          wealth of expertise to the table. Sivasri Engineering Services
          specializes in a spectrum of offerings including Embedded Software
          Development, Embedded Software Testing, and Verification & Validation.
          Moreover, the proprietor's extensive background encompasses Resource
          Management, as well as adept handling of Cost (Opex & Capex) and
          Facility Management Operations.
        </p>
        <br />
        <p>Indian Time: {indianTime}</p>
        <p>German Time: {germanTime}</p>
      </div>
    </div>
  );
}

export default Home;
