import React from "react";
import "./Contact.css";
import { FaPhone, FaMapMarkerAlt, FaUser } from "react-icons/fa";

function Contact() {
  return (
    <div className="contact-container" id="contact">
      <h2>Contact</h2>
      <div className="contact-content">
        <div className="contact-info">
          <div className="contact-item">
            <FaUser className="contact-icon" />
            <span>Sivarama Krishnaiah Gumpena</span>
          </div>
          <div className="contact-item">
            <FaPhone className="contact-icon" />
            <span>+91 9538881333</span>
          </div>
          <div className="contact-item">
            <FaMapMarkerAlt className="contact-icon" />
            <span>
              #415/46, C - Cross, Anjanadri Enclave, Babusa Palya, Horamavu
              Post, Near Vincent Palloti School, Bangalore 560113, Karnataka,
              India
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
