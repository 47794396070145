import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-scroll";
import icon from "../../assets/logo.png";

function Header() {
  const [scroll, setScroll] = useState(false);
  const navOptions = ["home", "about", "services", "contact"];

  useEffect(() => {
    const updateScroll = () => {
      window.scrollY >= 90 ? setScroll(true) : setScroll(false);
    };
    window.addEventListener("scroll", updateScroll);
  }, []);

  return (
    <header>
      <div className="title">
        <img src={icon} alt="icon" className="icon-img" />
        <div className="company">SivaSri Engineering Services</div>
      </div>
      <nav>
        <ul className="flex items-center gap-8">
          {navOptions.map((option, i) => (
            <li key={i}>
              <Link
                to={option}
                offset={-80}
                smooth={true}
                duration={500}
                isDynamic={true}
              >
                {option}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
