import React from "react";
import "./Services.css";
import engineeringConsultancyIcon from "../../assets/engineering-consultancy.png";
import embeddedSoftwareDevelopmentIcon from "../../assets/embedded-software-development.png";
import embeddedSoftwareTestingIcon from "../../assets/embedded-software-testing.png";
import verificationValidationIcon from "../../assets/verification-validation.png";
import resourceManagementIcon from "../../assets/resource-management.png";
import costManagementIcon from "../../assets/cost-management.png";
import facilityManagementIcon from "../../assets/facility-management.png";
import vendorManagementIcon from "../../assets/vendor-management.png";

function Services() {
  return (
    <div className="services-container" id="services">
      <h2>Services</h2>
      <div className="services-content">
        <div className="service">
          <img
            src={engineeringConsultancyIcon}
            alt="Engineering"
            className="service-icon"
          />
          <p>Engineering Consultancy</p>
        </div>
        <div className="service">
          <img
            src={embeddedSoftwareDevelopmentIcon}
            alt="Embedded Development"
            className="service-icon"
          />
          <p>Embedded Software Development</p>
        </div>
        <div className="service">
          <img
            src={embeddedSoftwareTestingIcon}
            alt="Embedded Testing"
            className="service-icon"
          />
          <p>Embedded Software Testing</p>
        </div>
        <div className="service">
          <img
            src={verificationValidationIcon}
            alt="Verification & Validation"
            className="service-icon"
          />
          <p>Verification & Validation</p>
        </div>
        <div className="service">
          <img
            src={resourceManagementIcon}
            alt="Resource Management"
            className="service-icon"
          />
          <p>Resource Management</p>
        </div>
        <div className="service">
          <img
            src={facilityManagementIcon}
            alt="Facility Management"
            className="service-icon"
          />
          <p>Facility/Site Management</p>
        </div>
        <div className="service">
          <img
            src={costManagementIcon}
            alt="Cost Management"
            className="service-icon"
          />
          <p>Cost Management</p>
        </div>
        <div className="service">
          <img
            src={vendorManagementIcon}
            alt="Vendor Management"
            className="service-icon"
          />
          <p>Vendor Management</p>
        </div>
      </div>
    </div>
  );
}

export default Services;
