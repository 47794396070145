import React from "react";
import "./About.css";
import { FaUserTie, FaUniversity } from "react-icons/fa";

function About() {
  return (
    <div className="about-container" id="about">
      <h2>About</h2>
      <div className="about-content">
        <div className="section">
          <FaUserTie className="icon" />
          <div className="text">
            <h3>Educational Qualifications</h3>
            <ul>
              <li>
                B.Tech (Electronics & Communications) - JNTU College of
                Engineering, AP, India
              </li>
              <li>
                M.Tech (Instrumentation and Controls) - JNTU College of
                Engineering, AP, India
              </li>
              <li>
                M.Tech (Instrumentation, Controls & Guidance) - Indian Institute
                of Technology (IIT), Madras
              </li>
              <li>
                M.B.A (Human Resources) - Indira Gandhi National Open
                University, India
              </li>
              <li>PMP Certification from PMI</li>
            </ul>
          </div>
        </div>
        <div className="section">
          <FaUniversity className="icon" />
          <div className="text">
            <h3>Registrations</h3>
            <p>
              Registered with Government of India, Goods and Service Tax (GST)
              department and got approved. <br /> GST number is: 29ABOPG1156E1ZQ
            </p>
            <p>
              Registered with Government of India, Ministry of Micro, Small and
              Medium Enterprises. <br /> UDAYAM Registration number:
              UDYAM-KR-03-0370595
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
